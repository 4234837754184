* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/652/confectionary.png);
  height: 100%;
}

@media only screen and (max-width: 400px) {
}
