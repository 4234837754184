.NavbarItems > ul {
  /* background: linear-gradient(
    90deg,
    rgb(110, 94, 254) 0%,
    rgba(73, 63, 252, 1) 100%
  ); */
  height: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
  flex-direction: row;
  /* font-family: "DotGothic16", sans-serif; */
  font-family: "Balsamiq Sans", cursive;
}

.NavbarItems > ul > li {
  list-style-type: none;
}

a {
  color: black;
  text-decoration: none;
}

/* .appBar {
  color: #fff;
  background: lightgrey;
} */
